<template>
  <v-app>
    <Header @delete-local-storage="deleteLocalStorage"/>
    <v-main>
      <v-container>
       <router-view
       :books="books"
       @add-book-list="addBook"
       @update-book-info="updateBookInfo"
       />
      </v-container>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Header from '@/global/Header';
import Footer from '@/global/Footer';
const STORAGE_KEY = 'books'

export default {
  components: { Header, Footer },
  name: 'App',

  data(){
    return{
      books: [],
      newBook: null
    }
  },
  mounted() {
    if (localStorage.getItem(STORAGE_KEY)) {
    try {
        this.books = JSON.parse(localStorage.getItem(STORAGE_KEY));//json to object change
    } catch(e) {
        localStorage.removeItem(STORAGE_KEY);
    }
  }
  },
  methods: {
      addBook(e) {
          this.books.push({
            id: this.books.length,
            title: e.title,
            img: e.img,
            description: e.description,
            readDate: '',
            memo:''
          });
          // this.newBook = '';
          this.saveBooks();
          //最後に追加したidの取得コード
          //console.log(this.books.slice(-1)[0].id)

          //最新のidを取得する
          this.gotoEditPage(this.books.slice(-1)[0].id)
      },
      removeBook(x) {
          this.books.splice(x, 1);
          this.saveBooks();
      },
      saveBooks() {
          const parsed = JSON.stringify(this.books);//object to json string format change
          localStorage.setItem(STORAGE_KEY, parsed);
      },
      updateBookInfo(e){
        const updateInfo = {
          id: e.id,
          readDate:e.readDate,
          memo:e.memo,
          title:this.books[e.id].title,
          img:this.books[e.id].img,
          description:this.books[e.id].description
        }
        this.books.splice(e.id, 1, updateInfo)
        this.saveBooks()
        this.$router.push('/')//index pageに戻る
      },
      gotoEditPage(id){
          this.$router.push(`/edit/${id}`)
      },
      deleteLocalStorage()
      {
        const isDeleted = 'LocalStorageのデータを削除してもいいですか?'
        if(window.confirm(isDeleted)){
          localStorage.setItem(STORAGE_KEY, '');
          localStorage.removeItem(STORAGE_KEY)
          this.books = []
          //現在のURLで再読み込みがかかる形なる
          window.location.reload()
        }
      }
  }
};
</script>
