<template>
  <div>
    フッター
  </div>
</template>

<script>
export default {
    name: "FooterView"
}
</script>

<style>

</style>